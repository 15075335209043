import User from 'types/Users/User';

function isClientShareLinkUser(userPrincipalName: string) {
    return /^clientlink-[a-zA-Z\d]{8}-[a-zA-Z\d]{4}-[a-zA-Z\d]{4}-[a-zA-Z\d]{4}-[a-zA-Z\d]{12}$/.test(
        userPrincipalName
    );
}

const start = ({ userPrincipalName, email, displayName, title, department }: User) => {
    const names = displayName.split(' ');
    if (userPrincipalName && window.pendo) {
        const clientShareUser = isClientShareLinkUser(userPrincipalName);
        window.pendo.initialize({
            visitor: {
                id: clientShareUser ? email : userPrincipalName,
                email,
                firstName: names[0],
                lastName: names.pop() || '',
                role: `${department} - ${title}`,
                isClientUser: clientShareUser,
            },
            account: {
                id: 'Blackbird',
            },
        });
    }
};

export default {
    start,
};
