import { createEndpoint } from './apiClientUtils';

const endpoints = {
    configuration: createEndpoint('Configuration', ['get']),
    availabilities: {
        searchData: createEndpoint('Availabilities/SearchData/{marketId}', ['get']),
        byId: createEndpoint('Availabilities/{availabilityId}', ['get']),
        floorsByMarket: createEndpoint('Availabilities/FloorsByMarketId/{marketId}', ['get']),
        byPropertyAndMarketType: createEndpoint(
            'Availabilities/ByPropertyAndMarketType/{propertyId}/{marketType}',
            ['get']
        ),
    },
    propertyBalloons: {
        config: createEndpoint('PropertyBalloons/Config', ['get']),
    },
    properties: {
        byId: createEndpoint('Properties/{propertyProviderId}/{propertyId}', ['get']),
        buildingData: createEndpoint('Properties/BuildingData', ['get']),
        searchData: createEndpoint('Properties/SearchData/{marketId}', ['get']),
        cspMappings: createEndpoint('Properties/CSP/Mappings', ['get']),
        msKeyMetrics: createEndpoint('Properties/MarketSphere/{propertyId}/KeyMetrics', ['get']),
        download: createEndpoint('Properties/Download/{marketId}', ['get']),
        downloadKmz: createEndpoint('Properties/Download/{marketId}/Kmz', ['get']),
        propertyTypeCodes: createEndpoint('Properties/PropertyTypeCodes', ['get']),
        analytics: createEndpoint(
            'Properties/{propertyProviderId}/Analytics/{analyticsType}/{marketId}',
            ['post']
        ),
        sales: createEndpoint('Properties/MarketSphere/{propertyId}/Sales', ['get']),
        reportById: createEndpoint('Properties/{propertyProviderId}/Report/{blackbirdId}', ['get']),
        report: createEndpoint('Properties/{propertyProviderId}/Report', ['post']),
        reportIntersected: createEndpoint(
            'Properties/{propertyProviderId}/Report/{marketId}/Intersected',
            ['post']
        ),
        tenants: createEndpoint('Properties/MarketSphere/Tenants/{propertyId}', ['get']),
    },
    kml: {
        upload: createEndpoint('Kml/Upload', ['post']),
        download: createEndpoint('Kml/{layerId}/Download', ['get']),
        edit: createEndpoint('Kml/{layerId}', ['post']),
        categories: createEndpoint('Kml/Categories', ['get']),
        all: createEndpoint('Kml', ['get']),
        security: createEndpoint('Kml/{layerId}/Security', ['get']),
        associations: createEndpoint('Kml/{layerId}/Associations', ['get']),
        archive: createEndpoint('Kml/{layerId}/Archive', ['post', 'delete']),
        search: createEndpoint('Kml/Search', ['get']),
        status: createEndpoint('Kml/{layerId}/Status', ['get']),
        regenerate: createEndpoint('Kml/{layerId}/Regenerate', ['post']),
        layers: createEndpoint('Kml/Presentation/{presentationId}/Layers', ['get']),
        sitePlansLayers: createEndpoint('Kml/SitePlans/Layers', ['get']),
        sitePlansByLayerId: createEndpoint('Kml/{layerId}/SitePlans', ['get']),
    },
    csv: {
        save: createEndpoint('CsvLayer/Save', ['post']),
        remove: createEndpoint('CsvLayer/{id}', ['delete']),
        signedUrl: createEndpoint('CsvLayer/{id}/SignedUrl', ['get']),
        uploadUrl: createEndpoint('CsvLayer/{id}/UploadUrl', ['get']),
    },
    imageLayers: {
        upload: createEndpoint('ImageLayer/Upload', ['post']),
        list: createEndpoint('ImageLayer/List', ['get']),
        archive: createEndpoint('ImageLayer/{id}/Archive', ['post', 'delete']),
    },
    layerTiles: {
        details: createEndpoint('LayerTile/{layerId}/Details', ['get']),
        configUrl: createEndpoint('LayerTile/{layerId}/ConfigurationUrl', ['get']),
        configurations: createEndpoint('LayerTile/Configurations', ['get']),
        tile: createEndpoint('LayerTile', ['get']),
        balloonStyle: createEndpoint('LayerTile/BalloonStyle', ['get']),
        regenerate: createEndpoint('LayerTile/Regenerate', ['post']),
        configurationStatus: createEndpoint('LayerTile/Configurations/{layerId}/Status', ['get']),
    },
    mediaAssets: {
        byId: createEndpoint('MediaAssets/MarketSphere/{objectId}/{objectTypeCode}', ['get']),
        primaryPhoto: createEndpoint(
            'MediaAssets/{presentationId}/{blackbirdId}/PrimaryPhoto/{sourceType}/{mediaAssetId}',
            ['post']
        ),
        primaryPhotos: createEndpoint('MediaAssets/PrimaryPhotos/{objectTypeCode}', ['get']),
        setPrimaryMediaAsset: createEndpoint(
            'MediaAssets/{presentationId}/{blackbirdId}/PrimaryAsset/{sourceType}/{mediaAssetId}',
            ['post']
        ),
        propertyAssets: createEndpoint('MediaAssets/Properties/{blackbirdId}', ['get']),
        uploadPhoto: createEndpoint('MediaAssets/{presentationId}/{blackbirdId}/Upload/Photo', [
            'post',
        ]),
        uploadFloorPlan: createEndpoint(
            'MediaAssets/{presentationId}/{blackbirdId}/Upload/FloorPlan',
            ['post']
        ),
        uploadDocument: createEndpoint(
            'MediaAssets/{presentationId}/{blackbirdId}/Upload/Document',
            ['post']
        ),
        addWebsite: createEndpoint('MediaAssets/{presentationId}/{blackbirdId}/Upload/Website', [
            'post',
        ]),
        getDocumentUrl: createEndpoint('MediaAssets/Documents/{mediaAssetId}', ['get']),
        remove: createEndpoint('MediaAssets/{mediaAssetId}', ['delete']),
    },
    users: {
        current: createEndpoint('Users/Current', ['get']),
        settings: createEndpoint('UserSettings', ['get', 'post']),
        ignoredAlerts: createEndpoint('UserSettings/IgnoredAlerts/{alertId}', ['put']),
        logout: createEndpoint('Users/Logout', ['post']),
        byEmails: createEndpoint('Users/ByEmails', ['get']),
        marketAdmins: createEndpoint('Users/MarketAdmins', ['post']),
        groupSearch: createEndpoint('Users/Groups/Search', ['get']),
        search: createEndpoint('Users/Search', ['get']),
        photo: createEndpoint('Users/Photo', ['get']),
    },
    markets: {
        all: createEndpoint('Markets', ['get']),
        save: createEndpoint('Markets/Save', ['post']),
        lastAccessed: createEndpoint('Markets/LastAccessed', ['get']),
        listDetailed: createEndpoint('Markets/ListDetailed', ['get']),
        nearmapEnabled: createEndpoint('Markets/NearmapEnabled', ['get']),
        baseLayers: createEndpoint('Markets/{marketId}/BaseLayers', ['get']),
        marketSphere: createEndpoint('Markets/MarketSphere', ['get']),
        userMarketRoles: createEndpoint('Markets/{marketId}/UserMarketRoles', ['get', 'post']),
        search: createEndpoint('Markets/Search', ['get']),
        byId: createEndpoint('Markets/{marketId}', ['get']),
        marketBoundary: createEndpoint('Markets/{marketId}/MarketBoundary', ['get']),
    },
    presentations: {
        customData: createEndpoint(
            'PresentationCustomData/{presentationId}/building/{blackbirdId}',
            ['get', 'post']
        ),
        save: createEndpoint('Presentations/{presentationId}', ['post']),
        delete: createEndpoint('Presentations/{presentationId}/Delete', ['post']),
        feedback: createEndpoint('Presentations/Feedback', ['post']),
        publish: createEndpoint('Presentations/{presentationId}/Publish', ['post']),
        revokePublish: createEndpoint('Presentations/{presentationId}/RevokePublish', ['post']),
        rename: createEndpoint('Presentations/{presentationId}/Rename', ['post']),
        open: createEndpoint('Presentations/{presentationId}/Open', ['get']),
        shareStatistics: createEndpoint('Presentations/{presentationId}/ShareStatistics', ['get']),
        shareWithUsers: createEndpoint('Presentations/{presentationId}/Share/Users', ['post']),
        shareWithGroups: createEndpoint('Presentations/{presentationId}/Share/Groups', ['post']),
        shareWithExternalClient: createEndpoint('Presentations/{presentationId}/Share/Clients', [
            'post',
        ]),
        getMagicLink: createEndpoint('Presentations/{presentationId}/Share/MagicLink', ['post']),
        renewExternalClientShare: createEndpoint(
            'Presentations/{presentationId}/Share/Clients/Renew',
            ['post']
        ),
        resendExternalClientShareEmail: createEndpoint(
            'Presentations/{presentationId}/Share/Clients/ResendEmail',
            ['post']
        ),
        shareRecentUsersAndGroups: createEndpoint('Presentations/Share/RecentUsersAndGroups', [
            'get',
        ]),
        byMarketCodes: createEndpoint('Presentations/ByMarketCodes', ['get']),
        byCsp: createEndpoint('Presentations/CSP', ['get']),
        search: createEndpoint('Presentations/Search', ['get']),
        featured: createEndpoint('Presentations/{presentationId}/Featured', ['post', 'delete']),
        allFeatured: createEndpoint('Presentations/Featured', ['get']),
        favorite: createEndpoint('Presentations/{presentationId}/Favorite', ['post', 'delete']),
        allFavorite: createEndpoint('Presentations/Favorite', ['get']),
        defaultPresentation: createEndpoint('Presentations/Default', ['get']),
        generateReport: createEndpoint('Presentations/PropertyReport', ['post']),
        revokeAccess: createEndpoint('Presentations/{presentationId}/Share/Clients/{email}', [
            'delete',
        ]),
        changeOwner: createEndpoint('Presentations/{presentationId}/Owner/{userId}', ['post']),
        security: createEndpoint('Presentations/{presentationId}/Security', ['get']),
        clientVersion: createEndpoint('Presentations/{presentationId}/ClientVersion', ['get']),
        dataProvider: createEndpoint('Presentations/{presentationId}/DataProvider', ['get']),
    },
    propertyGeometries: {
        tiles: createEndpoint('PropertyGeometries/Tiles/{level}/{x}/{y}', ['get']),
        availableTiles: createEndpoint('PropertyGeometries/Tiles/AvailableTiles', ['get']),
        missingPolygons: createEndpoint('PropertyGeometries/MissingPolygons', ['get']),
        geometryLatestModifiedDate: createEndpoint(
            'PropertyGeometries/GeometryLatestModifiedDate',
            ['get']
        ),
        propertyCore: createEndpoint('PropertyGeometries/PropertyCore/Save', ['post']),
        cspPropertyCore: createEndpoint('PropertyGeometries/PropertyCore/CSP/Save', ['post']),
        getPropertyGeometryByBlackbirdId: createEndpoint(
            'PropertyGeometries/PropertyCore/{blackbirdId}/Geometry',
            ['get']
        ),
        getModifiedPropertyCorePolygons: createEndpoint(
            'PropertyGeometries/ModifiedPropertyCorePolygons',
            ['get']
        ),
        getBlackbirdPropertyMappings: createEndpoint(
            'PropertyGeometries/BlackbirdPropertyMappings/{marketId}',
            ['get']
        ),
    },
    customIcons: {
        icons: createEndpoint('CustomIcons', ['get']),
        icon: createEndpoint('CustomIcons/{iconId}', ['get', 'delete']),
        upload: createEndpoint('CustomIcons', ['post']),
        save: createEndpoint('CustomIcons/Save', ['post']),
        all: createEndpoint('CustomIcons/All', ['get']),
    },
    noop: createEndpoint('NoOp', ['post']),
    building3dTiles: {
        configuration: createEndpoint('Building3dTiles/Configuration', ['get']),
        tile: createEndpoint('Building3dTiles/{level}/{x}/{y}', ['get']),
    },
    leaseComps: {
        floorsByMarket: createEndpoint('LeaseComps/FloorsByMarketId/{marketId}', ['get']),
        searchData: createEndpoint('LeaseComps/SearchData/{marketId}', ['get']),
        byPropertyId: createEndpoint(
            'LeaseComps/MarketTypes/{marketTypeId}/Properties/{propertyId}',
            ['get']
        ),
    },
    buildingCustomData: {
        lease: createEndpoint('BuildingCustomData/{marketId}/{blackbirdId}/Lease', ['get', 'post']),
    },
    searchFilters: {
        configuration: createEndpoint('SearchFilters/{marketId}/Configuration', ['get']),
        range: createEndpoint('SearchFilters/{marketId}/{propertyProvider}/{searchType}/Range', [
            'get',
        ]),
        list: createEndpoint('SearchFilters/{marketId}/{propertyProvider}/{searchType}/List', [
            'get',
        ]),
        signedUrl: createEndpoint('SearchFilters/Markets/SignedUrl', ['get']),
    },
    search: {
        properties: createEndpoint('Search/{propertyProvider}/Properties/{marketId}', ['get']),
        availabilities: createEndpoint('Search/Availabilities/{marketId}', ['get']),
        leaseComps: createEndpoint('Search/LeaseComps/{marketId}', ['get']),
        sales: createEndpoint('Search/Sales/{marketId}', ['get']),
    },
    sales: {
        searchData: createEndpoint('Sales/SearchData/{marketId}', ['get']),
    },
    activityLogs: {
        openPresentation: createEndpoint('ActivityLogs/Presentations/{presentationId}/Open', [
            'post',
        ]),
        openSurvey: createEndpoint('ActivityLogs/Surveys/{surveyId}/Open', ['post']),
        presentations: createEndpoint('ActivityLogs/Presentations', ['post']),
        surveys: createEndpoint('ActivityLogs/Surveys', ['post']),
        login: createEndpoint('ActivityLogs/Login', ['post']),
        logout: createEndpoint('ActivityLogs/Logout', ['post']),
        browserEvents: createEndpoint('ActivityLogs/BrowserEvents', ['get', 'post']),
        browserEventsBatch: createEndpoint('ActivityLogs/BrowserEvents/Batch', ['post']),
    },
    staticPresentation: {
        generate: createEndpoint('StaticPresentation/{presentationId}/Generate/{format}', ['post']),
    },
    yelpFusion: {
        autocomplete: createEndpoint('YelpFusion/Autocomplete', ['get']),
        configuration: createEndpoint('YelpFusion/Configuration', ['get']),
        businessById: createEndpoint('YelpFusion/Business', ['get']),
        categories: createEndpoint('YelpFusion/Categories', ['get']),
        search: createEndpoint('YelpFusion/Search', ['get']),
        searchWithFilters: createEndpoint('YelpFusion/SearchWithFilters', ['get']),
    },
    highlightSets: {
        all: createEndpoint('HighlightSets', ['get']),
        save: createEndpoint('HighlightSets/Save', ['post']),
        archive: createEndpoint('HighlightSets/{id}/Archive', ['post']),
        unarchive: createEndpoint('HighlightSets/{id}/Unarchive', ['post']),
    },
    locations: {
        directions: createEndpoint('Locations/Directions', ['get']),
        distancematrix: createEndpoint('Locations/DistanceMatrix', ['get']),
        geocode: createEndpoint('Locations/Geocode', ['get']),
    },
    nearmapTiles: {
        list: createEndpoint('NearmapTiles/List', ['get']),
        access: createEndpoint('NearmapTiles/{id}/Access', ['get']),
    },
    stackingPlans: {
        byPropertyId: createEndpoint('StackingPlans/{propertyId}', ['get']),
        all: createEndpoint('StackingPlans/{propertyId}/All', ['get']),
        sales: createEndpoint('StackingPlans/{propertyId}/Sales', ['get']),
        recentLeases: createEndpoint('StackingPlans/{propertyId}/Leases/Recent', ['get']),
        availabilities: createEndpoint('StackingPlans/{propertyId}/Availabilities', ['get']),
    },
    leases: {
        byId: createEndpoint('Leases/{leaseId}', ['get']),
    },
    companies: {
        owners: createEndpoint('Companies/{propertyId}/Owners', ['get']),
        leasingAgencies: createEndpoint('Companies/{propertyId}/LeasingAgencies', ['get']),
        recentSale: createEndpoint('Companies/{propertyId}/RecentSale', ['get']),
    },
    marketViews: {
        list: createEndpoint('MarketViews/MarketSphere/{marketId}', ['get']),
        search: createEndpoint(
            'MarketViews/{marketViewId}/MarketSphere/{marketId}/Search/{searchTypeId}',
            ['post']
        ),
    },
    submarkets: {
        statistics: createEndpoint('Submarkets/Statistics', ['post']),
        bySubmarketCode: createEndpoint(
            'Submarkets/{propertyTypeCode}/{marketCode}/{submarketCode}/Statistics',
            ['get']
        ),
        colors: createEndpoint('Submarkets/{marketId}/Colors', ['get', 'post']),
    },
    surveyPresentations: {
        pursuitMapping: createEndpoint('SurveyPresentations/PursuitMapping', ['get']),
    },
    mapIt: {
        token: createEndpoint('MapIt/Token', ['get']),
    },
    researchers: {
        all: createEndpoint('ResearchRoster', ['get']),
    },
    trepBi: {
        csv: createEndpoint('TrepBi', ['post']),
    },
    cloudinary: {
        signature: createEndpoint('Cloudinary/Signature', ['get']),
    },
    signup: {
        withShareToken: createEndpoint('signup/sharetoken', ['post']),
    },
    magicLink: {
        track: createEndpoint('Presentations/Share/MagicLink/Track', ['post']),
    },
    assistant: {
        search: createEndpoint('Assistant/Search', ['get']),
    },
    quickLayer: {
        create: createEndpoint('QuickLayer/Create', ['post']),
        update: createEndpoint('QuickLayer/Update', ['put']),
        remove: createEndpoint('QuickLayer/{id}', ['delete']),
        signedUrl: createEndpoint('QuickLayer/{id}/SignedUrl', ['get']),
        search: createEndpoint('QuickLayer/Search', ['get']),
        saveAsLayer: createEndpoint('QuickLayer/{id}/Save', ['post']),
        gallerySignedUrl: createEndpoint('QuickLayer/Gallery/SignedUrl', ['get']),
    },
    marketSphereOsmMapping: {
        mappings: createEndpoint('MarketSphereOsmMapping', ['get']),
        save: createEndpoint('MarketSphereOsmMapping/Save', ['post']),
        filterMs: createEndpoint('MarketSphereOsmMapping/FilterMs', ['post']),
        filterOsm: createEndpoint('MarketSphereOsmMapping/FilterOsm', ['post']),
        blackbirdIdsByMarketSphereIds: createEndpoint(
            'MarketSphereOsmMapping/Search/BlackbirdIds',
            ['post']
        ),
    },
    buildingExcludedSettings: {
        settings: createEndpoint('BuildingsExcludeSettings', ['get']),
        filter: createEndpoint('BuildingsExcludeSettings/{layer}', ['get']),
        save: createEndpoint('BuildingsExcludeSettings/{layer}/Save', ['post']),
    },
};

export default endpoints;
